<template>
  <div>
    <router-link
      :to="{ name: 'view_forum_topic', params: { id: forum.id } }"
      class="forum-preview"
    >
      <div class="card mb-5">
        <div class="card-header px-4 pt-4 pb-2 border-bottom-0 d-flex justify-content-between">
          <p class="font-bold mb-0 font-size-lg font-weight-bold text-dark">{{ forum.topic }}</p>
          <span
            class="text-muted font-weight-normal font-size-sm"
          >
            Added: 
            <GenericDatetimeDisplay
              :long="true"
              :date="forum.created_at"
              :createdAt="true"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
        </div>
        <div class="card-body px-4 py-0">
          <p class="text-muted mb-0 font-size-sm">
            {{ forum.description }}
          </p>
        </div>
        <div class="card-footer px-4 pt-5 pb-4 border-top-0">
          <span
            class="btn btn-hover-text-primary btn-hover-icon-primary btn-sm font-size-xs btn-text-dark-50 bg-hover-gray-100 rounded font-weight-bolder p-2"
          >
            <i class="fa fa-comment-alt text-success mr-2 font-size-sm" style="font-size:1rem"></i>
            {{ forum.totalResponseCount }}
            {{
              forum.totalResponseCount > 1
                ? $t("RESPONSE_FORMS.LABELS.REPLIES")
                : $t("RESPONSE_FORMS.LABELS.REPLY")
            }}
          </span>

          <!-- <span
            href="javascript:void(0)"
            class="btn btn-icon-danger btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder btn-sm font-size-xs p-2"
          >
            <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
              <i class="far fa-heart text-danger" style="font-size:1rem" />
            </span
            >{{ 10 }}
          </span> -->
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

export default {
  name: "ForumPreview",

  props: {
    forum: { type: Object, required: true }
  },

  components: {
    GenericDatetimeDisplay
  }
}
</script>

<style scoped>
  .forum-preview .card-header {
    border-radius: 0px !important;
  }

 .forum-preview:hover .card-header {
  background: #f0f1f2;
 }

 .forum-preview:hover .card-body {
  background: #f0f1f2;
 }

 .forum-preview:hover .card-footer {
  background: #f0f1f2;
 }
</style>