<template>
  <div v-if="projectInfo.enableForums == true" class="row">
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div
            v-if="pageLoader.getStatus() == false"
            class="d-flex justify-content-center mb-3"
          >
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>

          <div v-else>
            <h5 class="ml-2"> {{ $t('FORUM.VIEW.TITLE') }} </h5>
            <p class="ml-2 mb-5"> {{ $t('FORUM.VIEW.DESCRIPTION') }} </p>

            <template v-for="(forum, f) in projectForumList">
              <ForumPreview
                :key="f"
                :forum="forum"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";

import PageLoader from "@/core/classes/PageLoader";
import ForumPreview from "@/view/components/forum/ForumPreview.vue";

export default {
  name: "ViewForum",

  data() {
    return {
      pageLoader: new PageLoader(1)
    }
  },

  mounted() {
    this.fetchForumTopics();
  },

  components: {
    ForumPreview
  },

  methods: {
    fetchForumTopics: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/forums`,
        params: {
          paginate: false
        }
      }

      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.setProjectForumList(res._embedded.forums);
          this.pageLoader.setCompleteComponent();
        })
        .catch((error) => {
          this.setProjectForumErrors(error); 
        })
    },
    ...mapMutations({
      setProjectForumList: "setProjectForumList",
      setProjectForumErrors: "setProjectForumErrors"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectForumList"
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    }
  }
}
</script>